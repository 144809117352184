import { EventBuilder } from './_jquery.ga-lib'
//Add your Google Analytics Events below
//ga('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue], [fieldsObject]);

//CATEGORY: Contact

jQuery(($) => {
  const eventBuilder = new EventBuilder(!!TG.gaDebug)

  eventBuilder.bind('a[href^="mailto:"], a[href^="tel:"]')
  eventBuilder.bind('form[id^="gform_"]', {
    // name: 'Form Submitted',
    trigger: 'submit'
  })

  eventBuilder.bind('.contact-addr a', {
    name: 'Address Clicked',
    beforeLabel: 'Address Clicked | '
  })
})