import jQueryBridget from 'jquery-bridget';
import Masonry from 'masonry-layout';

jQuery(($) => {
    jQueryBridget('masonry', Masonry, $);
    var masonryActive = false;
    
    if ($(window).width() > 960) {
        $('.collage-grid').addClass('off');
        collageDesktop();
    } else {
        collageMobile();
    }

    function collageMobile() {

        if(masonryActive === true) {
            $('.collage-grid').masonry('destroy');
            $.removeData('masonry');
            masonryActive = false;
        }

        $('.collage__top').append($('.collage__content'));
        $('.collage-grid .grid-sizer').remove();

        $('.collage-grid').removeClass('off');
        $('.collage-grid').addClass('owl-carousel');

        $('.collage-grid').owlCarousel({
            loop: true,
            dots: true,
            margin: 32,
            nav: true,
            navText: ['<i class="fas fa-caret-left"></i>','<i class="fas fa-caret-right"></i>'],
            items: 1
        })
    }

    function collageDesktop() {
        $('.collage-grid').prepend($('.collage__content'));
        
        if (!$('.collage-grid .grid-sizer').length){
            $('<div class="grid-sizer"></div>').appendTo('.collage-grid');
        }
        
        var owl = $('.collage-grid');
        owl.trigger('destroy.owl.carousel');
        owl.addClass('off');
        owl.removeClass('owl-carousel');

        $('.collage-grid').masonry({
            itemSelector: '.grid-item',
            percentPosition: true
        });

        masonryActive = true;
    }

    $(window).on('resize', () => {
        if ($(window).width() < 960) {
            collageMobile();
        } else {
          setTimeout(collageDesktop, 500)
        }
    });
});