jQuery(document).ready(function($){

    const NAV = {
        classes : {
            Scrolling : 'scrolling',
            Fixed : 'fixed-top'
        },
        elements : {
            nav : jQuery('#navbar--primary'),
            header : jQuery('#header--site')
        },
        isScrolled : () => {
            let scrolled = false;
            if ($(window).scrollTop() > NAV.elements.header.height()) {
                scrolled = true;
            }
            return scrolled;
        }
    }

    let $siteHeader = $('#header--site');
    let $primaryNav = $('#navbar--primary');


    $(window).on('load scroll', function() {

        let currentOffset = $(window).scrollTop();
        let headerHeight = $siteHeader.height();

        if (NAV.isScrolled()) {
            $primaryNav.addClass(NAV.classes.Scrolling);
        }

        if (currentOffset > headerHeight*2) {
            $primaryNav.addClass('fixed-top');
        } else if (currentOffset < headerHeight) {
            $primaryNav.removeClass('fixed-top').removeClass('scrolling');
        }
    });






    // ==============================================
    // Drawer Navigation Toggle
    // ===============================================*/
    let $drawerNavigation   = $('.navbar--wrapper');
    let $drawerToggle       = $('button.navbar-toggle');
    let $body               = $('body');
    
    $drawerToggle.click( function(){
        $drawerNavigation.toggleClass('open');
        $body.toggleClass('nav-open'); 
    });
    
    $( window ).resize(function() {
        if ($drawerNavigation.hasClass('open')){
            $drawerNavigation.removeClass('open'); 
        }
        if ($body.hasClass('nav-open')){
            $body.removeClass('nav-open'); 
        }
    });
    
    $( window ).on('orientationchange', function() {
        if ($drawerNavigation.hasClass('open')){
            $drawerNavigation.removeClass('open'); 
        }
        if ($body.hasClass('nav-open')){
            $body.removeClass('nav-open'); 
        }
    });

    
    // ==============================================
    // Drawer Navigation Fade Effect
    // ===============================================*/
    let $window         = $(window);
    let $parentElements = $('.dropdown-toggle');
    let $navElements    = $('.dropdown-item');
    let $navDrawer      = $navElements.closest('.navbar--wrapper');
    
    function checkWidth() {
        let $windowsize = $window.width();
        
        if ($windowsize < 1200 ) {
             
            //Hides top level elements
            $parentElements.each( function(){
                
                let $subNavTrigger = $(this);
                    
                $subNavTrigger.on('click', function(){
                    let $trigger            = $(this);
                    let $triggerParent      = $(this).closest('li');
                    let $triggerParentSibs  = $(this).closest('li').siblings();
                    let $triggerChildren    = $triggerParent.find('ul'); 
                    
                    $triggerChildren.each( function(){
                        $(this).toggleClass('show-menu');
                    });
                    
                    $triggerParentSibs.toggleClass('mobile-hidden');
                })
            });
            
            //Closes drawer
            $navElements.each( function(){
                let $navElement = $(this);
                    
                $navElement.on('click', function(){
                    $navDrawer.removeClass('open');
                })
            });
        }
    }
    // Execute on load
    checkWidth();
    // Bind event listener
    $(window).resize(checkWidth);

    
    // ==============================================
    // Drawer Navigation Element Cloaning
    // ===============================================*/
    // $parentElements.each( function(){

    //     let $subNavTrigger        = $(this);
    //     let $subNavItem           = $subNavTrigger.parent('li');
    //     let $subNavMenuFirst      = $subNavTrigger.next('.dropdown-menu').find('.menu-item:first-child');
    //     let $subNavItemCloned     = $subNavItem.clone();
        
    //     $subNavTrigger.on('click', function(){
    //         $subNavItemCloned.insertBefore( $subNavMenuFirst );
    //     })
    // });




});