import 'owl.carousel';
import '@fancyapps/fancybox';
import jQueryBridget from 'jquery-bridget';
import Masonry from 'masonry-layout';
import './_navigation';
import './_img-collage';
import './_ga';

jQuery(($) => {

  jQueryBridget('masonry', Masonry, $);

  $("div.card--practice a").on('mouseover', function() {
    $("div.card--practice a").removeClass('active');
    $(this).addClass('active');
    $('div.practices-image').attr('style', 'background-image: url(' + $(this).data('image') +')');
  })

  // Projects Status Filter
  $(".projects__status-tab button").click(function() {
    resetProjectStatusFilter();
    $(this).addClass('active');
    var filterTarget = '#' + $(this).data('target');
    $(filterTarget).addClass('show');
  });

  function resetProjectStatusFilter(){
    $(".posts-by-status").removeClass('show');
    $(".projects__status-tab button").removeClass('active');
  }

  $('.home__main-carousel').owlCarousel({
    loop: true,
    dots: true,
    nav: false,
    // navText: ['<i class="fas fa-caret-left"></i>','<i class="fas fa-caret-right"></i>'],
    items: 1,
    animateOut: 'fadeOut'
  })

  $('.featured-awards-items').owlCarousel({
    loop: true,
    dots: false,
    // margin: 32,
    nav: true,
    navText: ['<i class="fas fa-caret-left"></i>','<i class="fas fa-caret-right"></i>'],
    responsive:{
        0:{
            items: 1
        },
        720:{
            items: 3
        },
        1000:{
            items: 4
        }
    }
  })

  $('.award-modal-carousel').owlCarousel({
    loop: false,
    dots: false,
    nav: true,
    navText: ['<i class="fas fa-caret-left"></i>','<i class="fas fa-caret-right"></i>'],
    items: 1
  })

  $('.related-post-carousel').owlCarousel({
    loop: true,
    dots: false,
    margin: 32,
    nav: true,
    autoHeight:false,
    navText: ['<i class="fas fa-caret-left"></i>','<i class="fas fa-caret-right"></i>'],
    responsive:{
      0:{
          items: 1
      },
      720:{
        items: 2
    },
    1000:{
        items: 3
    }
    }
  })

  if ($(window).width() > 720) {
    startCarousel();
  } else {
    $('.featured-press-items').addClass('off');
  }

  function startCarousel(){
    $('.featured-press-items').removeClass('off');

    $('.featured-press-items').owlCarousel({
        loop: true,
        dots: false,
        margin: 32,
        nav: true,
        navText: ['<i class="fas fa-caret-left"></i>','<i class="fas fa-caret-right"></i>'],
        responsive:{
            0:{
                items: 1
            },
            720:{
                items: 3
            },
            1000:{
                items: 4
            }
        }
    })
  }

  function stopCarousel() {
    var owl = $('.featured-press-items');
    owl.trigger('destroy.owl.carousel');
    owl.addClass('off');
  }

  $(window).on('resize', function() {
      if ($(window).width() < 720) {
          stopCarousel();
      } else {
          startCarousel();
      }
  });

  $.fn.isInViewport = function () {
    let elementTop = $(this).offset().top;
    let elementBottom = elementTop + $(this).outerHeight();

    let viewportTop = $(window).scrollTop();
    let viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  $(window).on('scroll', function () {
    if ($('.practice-icon').length > 0) {
      if ($('.practice-icon ').isInViewport()) {
          $('.practice-icon').addClass('onscreen');
          // console.log('success.')
      } else {
          $('.practice-icon').removeClass('onscreen');
          // console.log('No success.')
      }
    }
  });

  jQuery('.js-scrollto').on('click', function(e) {
    e.preventDefault()
    const $link = jQuery(this)
    const $target = jQuery($link.data('target'))

    window.scrollTo({
      top: $target.offset().top - jQuery('#navbar--primary').outerHeight(),
      behavior: 'smooth'
    })
  })


  $(document).on('show.bs.modal', '.modal--award', function(event)  {
    // const $modal = $(this);
    // const $trigger = $(event.relatedTarget)
    // let offset = $trigger.offset().top + $trigger.outerHeight()
    // if ($('body').hasClass('admin-bar')) {
    //   offset -= $('.admin-bar').height()
    // }
    // $modal.find('.modal-dialog').css('top', offset)
    $('.modal--award').not(event.currentTarget).each(function() {
      $(this).modal('hide')
    })
  })

});
